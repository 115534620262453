import './src/scss/purge-vendor.scss';
import './src/scss/style.scss';

const headerHeight = 125;

export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps
}) => {
  if (routerProps && routerProps.location && routerProps.location.hash) {
    const anchor = routerProps.location.hash;
    const el = document.getElementById(anchor.slice(1));

    if (el) {
      const top = el.offsetTop - headerHeight;
      window.scrollTo({
        top,
        behavior: 'smooth'
      });

      return false;
    }
  }

  if (
    !prevRouterProps || !prevRouterProps.location ||
    !routerProps || !routerProps.location
  ) {
    return true;
  }

  return prevRouterProps.location.pathname !== routerProps.location.pathname;
}
