// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-shopping-cart-js": () => import("./../../src/pages/shopping-cart.js" /* webpackChunkName: "component---src-pages-shopping-cart-js" */),
  "component---src-templates-about-page-js": () => import("./../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-admin-lessons-search-page-js": () => import("./../../src/templates/admin-lessons-search-page.js" /* webpackChunkName: "component---src-templates-admin-lessons-search-page-js" */),
  "component---src-templates-artists-list-page-js": () => import("./../../src/templates/artists-list-page.js" /* webpackChunkName: "component---src-templates-artists-list-page-js" */),
  "component---src-templates-custom-page-js": () => import("./../../src/templates/custom-page.js" /* webpackChunkName: "component---src-templates-custom-page-js" */),
  "component---src-templates-gifts-page-js": () => import("./../../src/templates/gifts-page.js" /* webpackChunkName: "component---src-templates-gifts-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-info-page-js": () => import("./../../src/templates/info-page.js" /* webpackChunkName: "component---src-templates-info-page-js" */),
  "component---src-templates-lesson-page-js": () => import("./../../src/templates/lesson-page.js" /* webpackChunkName: "component---src-templates-lesson-page-js" */),
  "component---src-templates-lessons-list-page-js": () => import("./../../src/templates/lessons-list-page.js" /* webpackChunkName: "component---src-templates-lessons-list-page-js" */),
  "component---src-templates-package-page-js": () => import("./../../src/templates/package-page.js" /* webpackChunkName: "component---src-templates-package-page-js" */),
  "component---src-templates-packages-list-page-js": () => import("./../../src/templates/packages-list-page.js" /* webpackChunkName: "component---src-templates-packages-list-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-specials-page-js": () => import("./../../src/templates/specials-page.js" /* webpackChunkName: "component---src-templates-specials-page-js" */),
  "component---src-templates-template-pages-old-target-customers-js": () => import("./../../src/templates/templatePages/oldTargetCustomers.js" /* webpackChunkName: "component---src-templates-template-pages-old-target-customers-js" */),
  "component---src-templates-template-pages-sales-page-long-js": () => import("./../../src/templates/templatePages/salesPageLong.js" /* webpackChunkName: "component---src-templates-template-pages-sales-page-long-js" */),
  "component---src-templates-template-pages-sales-page-short-js": () => import("./../../src/templates/templatePages/salesPageShort.js" /* webpackChunkName: "component---src-templates-template-pages-sales-page-short-js" */),
  "component---src-templates-template-pages-webinar-optin-js": () => import("./../../src/templates/templatePages/webinarOptin.js" /* webpackChunkName: "component---src-templates-template-pages-webinar-optin-js" */),
  "component---src-templates-template-pages-webinar-replay-js": () => import("./../../src/templates/templatePages/webinarReplay.js" /* webpackChunkName: "component---src-templates-template-pages-webinar-replay-js" */),
  "component---src-templates-terms-page-js": () => import("./../../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */)
}

